@charset "UTF-8";

html,
body,
#root {
  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: TMOBI, 'TMOBI300', 'Noto Sans', 'Roboto',
  AppleSDGothicNeo, AppleSDGothicNeo-Regular, '맑은 고딕', 'Malgun Gothic',
  '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica,
  HelveticaNeue, sans-serif;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #222;

  .logo {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
    background: url("../../assets/images/logo.svg") no-repeat 0 0 / 100% 100%;
  }
  .title {
    overflow: hidden;
    width: 230px;
    height: 20px;
    margin: 0 auto;
    color: transparent;
    font-size: 0;
    line-height: 200px;
    background: url("../../assets/images/tmapmobility.svg") no-repeat 0 0 / 100% 100%;
  }
  .build_number {
    margin: 30px 0 0;
    color: #909090;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
  }
  .service_list {
    min-width: 300px;
    margin: 10px auto 0;
    padding: 0;
    border: 1px solid #909090;

    &_item {
      margin: 0;
      padding: 10px;
      border-top: 1px solid #f1f1f7;
      list-style: none;
      font-size: 16px;

      &:first-child {
        border-top: 0;
      }
      .link {
        color: #0064ff;
        text-decoration: none;
      }
    }
  }
}
